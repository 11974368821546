.message-wall {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .message-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
 