@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 208, 100%, 97%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 215 70% 60%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --accent-rgb: 145, 71, 255; /* Adjust these values to match your accent color */
    --green: 77, 72%, 44%;
  }

  .dark {
    --background: 210 100% 6%;
    --foreground: 180 100% 90%;
    --primary: 200 100% 28%;
    --primary-foreground: 180 100% 90%;
    --secondary: 203 23% 30%;
    --secondary-foreground: 180 100% 90%;
    --accent: 198 70% 50%;
    --accent-foreground: 185 10% 13%;
    --destructive: 0 98% 44%;
    --destructive-foreground: 0 0% 100%;
    --muted: 200 50% 30%;
    --muted-foreground: 180 100% 90%;
    --card: 210 100% 12%;
    --card-foreground: 180 100% 90%;
    --popover: 210 100% 15%;
    --popover-foreground: 180 100% 90%;
    --border: 210 50% 40%;
    --input: 210 50% 40%;
    --ring: 180 100% 90%;
    --radius: 0rem;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-body;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
}

:root {
  --font-heading: 'Ubuntu', sans-serif;
  --font-body: 'Montserrat', sans-serif;
}

body {
  font-family: var(--font-body);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
}

/* Add this to your globals.css file */

.notification-icon {
  @apply flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center;
  background: radial-gradient(circle, var(--icon-bg-light) 0%, var(--icon-bg-dark) 100%);
}

.notification-icon-moderation {
  --icon-bg-light: hsl(var(--accent) / 0.2);
  --icon-bg-dark: hsl(var(--accent) / 0.3);
  @apply text-accent;
}

.notification-icon-event {
  --icon-bg-light: hsl(var(--primary) / 0.2);
  --icon-bg-dark: hsl(var(--primary) / 0.3);
  @apply text-primary;
}

.notification-icon-message {
  --icon-bg-light: hsl(var(--secondary) / 0.2);
  --icon-bg-dark: hsl(var(--secondary) / 0.3);
  @apply text-secondary;
}

.notification-icon-default {
  --icon-bg-light: hsl(var(--muted) / 0.2);
  --icon-bg-dark: hsl(var(--muted) / 0.3);
  @apply text-muted-foreground;
}

.notification-title {
  @apply text-sm font-medium text-foreground leading-5;
}

.notification-subtitle {
  @apply text-xs text-muted-foreground;
}

.notification-action {
  @apply text-xs font-medium text-muted-foreground hover:text-accent hover:bg-accent/10 rounded-full p-1 transition-colors duration-200;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 3px;
}

:root {
  --scrollbar-track: theme('colors.gray.100');
  --scrollbar-thumb: theme('colors.gray.300');
}

.dark {
  --scrollbar-track: theme('colors.gray.800');
  --scrollbar-thumb: theme('colors.gray.600');
}
